import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=7fcbc052"
import script from "./_id.vue?vue&type=script&lang=ts"
export * from "./_id.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductShop: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductShop.vue').default,PlaceholderProductShop: require('/home/runner/work/webapp/webapp/components/atoms/placeholder/PlaceholderProductShop.vue').default,InstantSearchSSR: require('/home/runner/work/webapp/webapp/components/organisms/instantsearch/InstantSearchSSR.vue').default})
