
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

import { ShopDetails } from '@/domain/shop/types';
import { createMetaTags } from '@/head/meta/tags';
import { EGtmEcommerceListName } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { _setCacheControlHeader } from '@/utilities/http/setCacheControlHeader';

export default Vue.extend({
  name: 'ShopPage',
  middleware: _setCacheControlHeader(null, true),
  async asyncData({ $repositories, params }): Promise<{ shop: ShopDetails }> {
    const shop = await $repositories.shop.getShopDetails(params.id);

    return {
      shop,
    };
  },
  data() {
    return {
      isSoldOutFilterActive: true, // Data drilled all the way down to InstantSearchFilters custom checkbox : shops/_id.vue <-> InstantSearchSSR.vue <-> InstantSearchFilters.vue.
      shop: {} as ShopDetails,
      trackingId: EGtmEcommerceListName.Shop,
    };
  },
  head(): MetaInfo {
    const sellerFirstName = this.shop?.user?.firstName;
    const description = this.$t('page.shop.meta.description', { sellerFirstName }) as string;
    const title = `${this.$t('page.shop.meta.title', { sellerFirstName })} | Selency` as string;

    return {
      title,
      meta: createMetaTags({
        description,
        title,
      }),
    };
  },
  computed: {
    sellerName(): string {
      const { firstName, lastNameInitial } = this.shop?.user || {};

      return `${firstName} ${lastNameInitial}.`;
    },
  },
});
