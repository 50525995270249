import { ValueOf } from 'type-fest';
import { actionTree, getterTree, mutationTree } from 'typed-vuex';
import { Component } from 'vue';

import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { EColor } from '@/domain/core/Color.enum';
import { HeaderDataAccentColor } from '@/infrastructure/core/service/data/HeaderData.interface';
import { accessorType } from '@/store';
import { _hexToHSL } from '@/utilities/colors/hexToHSL';

interface DialogElement {
  isOpen: boolean;
}

export type SidebarComponentId = ValueOf<typeof ESidebarComponentId>;
export type SidebarComponent = null | {
  component: Component;
  id: SidebarComponentId | null;
  props?: Record<string, unknown>;
};

interface SidebarComponents {
  currentComponent: SidebarComponent;
  previousComponent: SidebarComponent;
}

export type Sidebar = SidebarComponents & DialogElement;

export interface State {
  accentColor: {
    color: string;
    fontColor: string;
  };
  backdrop: {
    isVisible: boolean;
  };
  modal: DialogElement;
  sidebar: Sidebar;
}

export const state = (): State => ({
  accentColor: {
    color: '',
    fontColor: '',
  },
  backdrop: {
    isVisible: false,
  },
  sidebar: {
    currentComponent: null,
    isOpen: false,
    previousComponent: null,
  },
  modal: {
    isOpen: false,
  },
});

export const getters = getterTree(state, {
  getAccentColor: ({ accentColor }): string => _hexToHSL(accentColor.color) || `var(${EColor.Terracotta})`,
  getAccentFontColor: ({ accentColor }): string => _hexToHSL(accentColor.fontColor) || `var(${EColor.White})`,
});

export const mutations = mutationTree(state, {
  CLOSE_MODAL: (state): void => {
    state.modal.isOpen = false;
  },
  CLOSE_SIDEBAR: (state): void => {
    state.sidebar.isOpen = false;
  },
  HIDE_BACKDROP: (state): void => {
    state.backdrop.isVisible = false;
  },
  OPEN_MODAL: (state): void => {
    state.modal.isOpen = true;
  },
  OPEN_SIDEBAR: (state): void => {
    state.sidebar.isOpen = true;
  },
  SET_ACCENT_COLOR: (state, color: string): void => {
    state.accentColor.color = color;
  },
  SET_ACCENT_FONT_COLOR: (state, color: string): void => {
    state.accentColor.fontColor = color;
  },
  SET_SIDEBAR_COMPONENT: (state, options: SidebarComponents | null) => {
    state.sidebar.currentComponent = options?.currentComponent || null;
    state.sidebar.previousComponent = options?.previousComponent || null;
  },
  SHOW_BACKDROP: (state): void => {
    state.backdrop.isVisible = true;
  },
});

export const actions = actionTree({ state, getters, mutations }, {
  closeDialog(): void {
    const accessor: typeof accessorType = this.app.$accessor;

    accessor.ui.HIDE_BACKDROP();
    accessor.ui.CLOSE_SIDEBAR();
    accessor.ui.SET_SIDEBAR_COMPONENT(null);
    accessor.ui.CLOSE_MODAL();
  },
  openSidebar(_, options: SidebarComponents): void {
    if (options?.currentComponent) {
      const accessor: typeof accessorType = this.app.$accessor;

      accessor.ui.SET_SIDEBAR_COMPONENT(options);
      accessor.ui.OPEN_SIDEBAR();
      accessor.ui.SHOW_BACKDROP();
    }
  },
  openModal(_): void {
    const accessor: typeof accessorType = this.app.$accessor;

    accessor.ui.OPEN_MODAL();
    accessor.ui.SHOW_BACKDROP();
  },
  setAccentColor(_, { color, fontColor, isActive }: HeaderDataAccentColor): void {
    if (isActive) {
      const accessor: typeof accessorType = this.app.$accessor;

      accessor.ui.SET_ACCENT_COLOR(color);
      accessor.ui.SET_ACCENT_FONT_COLOR(fontColor);
    }
  },
});
