export const SchemaDotOrgUrl = 'https://schema.org';

export const ESchema = {
  AggregateRating: 'https://schema.org/AggregateRating',
  BreadcrumbList: 'https://schema.org/BreadcrumbList',
  InStock: 'https://schema.org/InStock',
  ListItem: 'https://schema.org/ListItem',
  Offer: 'https://schema.org/Offer',
  Product: 'https://schema.org/Product',
  SoldOut: 'https://schema.org/SoldOut',
  Thing: 'https://schema.org/Thing',
} as const;

export const ESchemaProperty = {
  Context: '@context',
  Type: '@type',
} as const;

export const ESchemaListItem = {
  ItemListElement: 'itemListElement',
} as const;

export const ESchemaOffer = {
  Availability: 'availability',
  Price: 'price',
  PriceCurrency: 'priceCurrency',
} as const;

export const ESchemaProduct = {
  AggregateRating: 'aggregateRating',
  Image: 'image',
  Name: 'name',
  Offers: 'offers',
  Url: 'url',
} as const;

export const ESchemaThing = {
  Item: 'item',
  Name: 'name',
  Position: 'position',
} as const;

export const ESchemaAggregateRating = {
  RatingValue: 'ratingValue',
  ReviewCount: 'reviewCount',
} as const;

export const ESchemaType = {
  AggregateRating: 'AggregateRating',
  Brand: 'Brand',
  MonetaryAmount: 'MonetaryAmount',
  Offer: 'Offer',
  OfferShippingDetails: 'OfferShippingDetails',
  Organization: 'Organization',
  Product: 'Product',
  SearchAction: 'SearchAction',
  Website: 'WebSite',
} as const;

// Source : https://schema.org/OfferItemCondition
export const ESchemaOfferItemCondition = {
  NewCondition: 'NewCondition',
  UsedCondition: 'UsedCondition',
} as const;
