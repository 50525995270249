var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"mode":_vm.transitionMode,"name":_vm.transitionName}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute z-10 transition-all",class:{
      'inset-x-0 transform p-10 min-h-[380px] w-full left-0 xl:max-w-6xl bg-white xl:left-[calc((100vw-72rem)/2)]': _vm.level < 2,
      'top-0 px-10 pb-4 left-full w-48': _vm.level >= 2,
    },style:(_vm.computedStyle)},[_c('div',{staticClass:"container relative z-10 flex justify-between mx-auto"},[_c('div',{class:{
          'w-1/2': _vm.level === 1,
        }},[(_vm.seeAllLink && _vm.seeAllLink.name)?_c('BaseLink',{staticClass:"mb-6 uppercase text-tiny",attrs:{"data-testid":"link-category-see-all","href":_vm.seeAllLink.url},on:{"click":function($event){return _vm.trackSubmenuClick(_vm.EGtmEventHeader.ClickLinkMainNav, { name: _vm.seeAllLink.name, url: _vm.seeAllLink.url })}}},[_vm._v("\n          "+_vm._s(_vm.$t('header.mainNavigation.seeAll', { category: _vm.seeAllLink.name }))+"\n        ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"relative",class:{
            'w-48': _vm.level < 2,
            'w-72': _vm.level >= 2,
          },on:{"mouseleave":_vm.handleMouseLeave}},_vm._l((_vm.navigation),function({ title, target, children, id, isNew, isPromotional },index){return _c('li',{key:id,staticClass:"submenu-item",class:{
              'hover:underline': id,
            },on:{"mouseover":function($event){return _vm.handleHover(index)}}},[(target)?_c('NavigationMainLink',{attrs:{"is-new":isNew,"is-promotional":isPromotional,"level":_vm.level,"target":target,"value":title},on:{"focus":function($event){return _vm.handleFocus(index, $event)}}}):_c('span',[_vm._v("\n              "+_vm._s(title)+"\n            ")]),_vm._v(" "),(children && children.length > 0)?_c('NavigationMainSubmenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === index),expression:"activeIndex === index"}],attrs:{"is-open":_vm.activeIndex === index,"level":2,"navigation":children}}):_vm._e()],1)}),0)],1),_vm._v(" "),(_vm.level === 1 && _vm.merchandisingBlocks.length > 0)?_c('div',{staticClass:"flex justify-end w-1/2 gap-4"},_vm._l((_vm.merchandisingBlocks),function({ title, image, url }){return _c('BaseLink',{key:url,attrs:{"href":url,"is-unstyled":"","title":title},on:{"click":function($event){return _vm.trackSubmenuClick(_vm.EGtmEventHeader.ClickPromoMainNav, { name: title || _vm.seeAllLink.name, url: _vm.link })}}},[_c('NuxtImg',{staticClass:"max-w-[240px]",attrs:{"alt":title || _vm.seeAllLink.name,"loading":"lazy","sizes":"lg:240px 2xl:480px","src":image && image.id || _vm.imagePlaceholderId}})],1)}),1):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.level < 2)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute inset-0 z-0 shadow-lg",attrs:{"aria-hidden":"true"}}):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }