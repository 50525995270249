export const EHttpHeader = {
  Accept: 'Accept',
  AcceptLanguage: 'Accept-Language',
  Authorization: 'Authorization',
  CacheControl: 'Cache-Control',
  CloudfrontUserCountryCode: 'Cloudfront-Viewer-Country', // Represents an ISO 3166-1 country code.
  CloudfrontUserIpAddress: 'X-Real-Ip',
  CloudfrontUserPostalCode: 'Cloudfront-Viewer-Postal-Code',
  Deprecation: 'Deprecation',
  XForwardedFor: 'X-Forwarded-For',
} as const;
