
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisSortBy, AisStats } from 'vue-instantsearch';

import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';
import { _capitalize } from '@/utilities/strings/capitalize';

export default Vue.extend({
  name: 'InstantSearchHeader',
  components: {
    AisSortBy,
    AisStats,
  },
  props: {
    algoliaSortIndices: {
      type: Array as PropType<BaseSelectOption[]>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    shouldDisplayFilters: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    capitalizedTitle(): string | null {
      return _capitalize(this.title);
    },
  },
  methods: {
    toggleFilters(): void {
      this.$emit('toggle-filters');
    },
  },
});
