
import Vue from 'vue';

import IcChevronRight from '@/assets/svg/ic_chevron_right.svg?inline';

export default Vue.extend({
  name: 'BaseCollapsible',
  components: {
    IcChevronRight,
  },
  props: {
    cssClassesContent: {
      type: String,
      default: '',
    },
    cssClassesButton: {
      type: String,
      default: '',
    },
    collapsibleId: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.isOpen,
    };
  },
  methods: {
    toggleCollapsible(): void {
      this.isExpanded = !this.isExpanded;

      this.$emit('update:collapse', {
        id: this.collapsibleId,
        isExpanded: this.isExpanded,
      });
    },
  },
});
