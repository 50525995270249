import { render, staticRenderFns } from "./NavigationMainSubmenu.vue?vue&type=template&id=2f4627f7&scoped=true"
import script from "./NavigationMainSubmenu.vue?vue&type=script&lang=ts"
export * from "./NavigationMainSubmenu.vue?vue&type=script&lang=ts"
import style0 from "./NavigationMainSubmenu.vue?vue&type=style&index=0&id=2f4627f7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4627f7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,NavigationMainLink: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationMainLink.vue').default,NavigationMainSubmenu: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationMainSubmenu.vue').default})
