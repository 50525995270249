
import Vue from 'vue';

import IcFlagFr from '@/assets/svg/flags/fr.svg?inline';
import IcFlagGb from '@/assets/svg/flags/gb.svg?inline';
import { ELanguage, ELanguageName } from '@/domain/core/Language.enum';
import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';

export default Vue.extend({
  name: 'SelectLocale',
  components: {
    IcFlagFr,
    IcFlagGb,
  },
  data() {
    return {
      ELanguage: Object.freeze(ELanguage),
    };
  },
  computed: {
    selectedLocale: {
      get() {
        return this.$i18n.locale;
      },
      set(newLocaleCode: string) {
        this.$i18n.setLocale(newLocaleCode);

        window.location.reload();
      },
    },
    localesOptions(): BaseSelectOption[] {
      return this.$i18n.localeCodes?.map((localeCode) => ({
        label: ELanguageName[localeCode.toUpperCase() as keyof typeof ELanguageName],
        value: localeCode,
      }));
    },
  },
});
