import { ValueOf } from 'type-fest';

import { Address, Country } from '@/domain/address/types';
import { PaginatedList, PaginationQueryParameters } from '@/domain/core/api/types';
import { LegacyCategories, Product, ProductIdentifier, UsableDeliveryOption } from '@/domain/product/types';

export interface DeliveryOptionsRequestParams {
  quantity: string;
  shippingAddressCity: string;
  shippingAddressCountry: string;
  shippingAddressPostcode: string;
  shippingAddressStreetname?: number | null;
}

export interface PayloadRequestDeliveryQuotation {
  addressCity?: Address['city'];
  addressCountryCode: Country['code'];
  addressPostCode: Address['postCode'];
  addressStreetName?: Address['streetName'];
}

export type SimilarProductsRequestParams = PaginationQueryParameters & { onlyPriceReduced?: boolean };

export const EProductsListIdentifierType = {
  LegacyIds: 'legacyIds',
  Skus: 'skus',
} as const;

export interface ProductRepositoryInterface {
  boost(id: ProductIdentifier): Promise<Product>;
  countProducts(userId: string, statuses: string[]): Promise<number>;
  getCategories(slug: string | null, parentSlug: string | null): Promise<LegacyCategories>;
  getCategory(id: string): Promise<LegacyCategories>;
  getProduct(id: ProductIdentifier): Promise<Product>;
  getProductLite(id: ProductIdentifier): Promise<Product>;
  getProducts(
    identifiersList: string[],
    identifierType?: ValueOf<typeof EProductsListIdentifierType>,
    parameters?: PaginationQueryParameters
  ): Promise<PaginatedList<Product> | null>;
  getSimilarProducts(id: ProductIdentifier, parameters?: SimilarProductsRequestParams): Promise<PaginatedList<Product>>;
  getUsableDeliveryOptions(productId: string, deliveryOfferRequestParams: DeliveryOptionsRequestParams): Promise<UsableDeliveryOption[]>;
  requestDeliveryQuotation(productId: string, payload: PayloadRequestDeliveryQuotation): Promise<void>;
}
