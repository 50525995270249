import { ValueOf } from 'type-fest';

import { Address } from '@/domain/address/types';
import { ECountryCodeISO31661 } from '@/domain/core/Country.enum';
import { Image } from '@/domain/core/image/types';
import { Language } from '@/domain/core/Language.enum';
import { ProviderType } from '@/domain/delivery/types';
import { Price } from '@/domain/pricing/types';
import { SeoRedirect } from '@/domain/seo/types';
import { ShopDetails, ShopId } from '@/domain/shop/types';

export const ECategoryLevelDepth = {
  Level1: 1,
  Level2: 2,
  Level3: 3,
} as const;

export type CategoryLevelDepth = ValueOf<typeof ECategoryLevelDepth>;

export const EQualityLevel = {
  OneStar: -1,
  TwoStars: 0,
  ThreeStars: 1,
} as const;

export type QualityLevel = ValueOf<typeof EQualityLevel>;

export const EProductStatus = {
  Accepted: 'accepted',
  AwaitingChanges: 'awaiting_changes',
  AwaitingCrop: 'awaiting_crop',
  New: 'new',
  PendingExpertize: 'pending_expertize',
  PendingExpertizeInternal: 'pending_internal_expertize',
  PendingReview: 'pending_review',
  Published: 'published',
  Rejected: 'rejected',
  Removed: 'removed',
  RemovedBySeller: 'removed_by_seller',
  SoldOut: 'sold_out',
  Unknown: 'unknown',
} as const;

export type ProductStatus = ValueOf<typeof EProductStatus>;

export interface LegacyCategoryElementI18n {
  description: string;
  editorial_name: string;
  id: string;
  language: string;
  listing_example: string | null;
  locale: string;
  meta_description: string;
  meta_title: string;
  name: string;
  slug: string;
}

export interface MerchandisingItem {
  image: string;
  language: string;
  link: string;
  title: string;
}

interface Creator {
  locale: string;
  user_id: string;
}

interface Color {
  createdAt?: string;
  id?: string;
  legacyId?: string;
  name: string;
  rgba?: string;
  slug: string;
  updatedAt?: string;
}

interface Designer {
  createdAt: string;
  id: string;
  name: string;
  slug: string;
  updatedAt: string;
}

export interface LegacyCategoryElement {
  active: boolean;
  archived_at: string | null;
  count_products: number | null;
  created_at: string;
  created_by: Creator | null;
  delivery_type_weight: string;
  description: string | null;
  dynamic_url: string | null;
  facets: [] | null;
  has_mandatory_dimensions: boolean;
  i18n: LegacyCategoryElementI18n[];
  id: string;
  images: {
    listing: string;
  };
  is_leaf: boolean;
  level: number;
  listing_example: string | null;
  main_language: string;
  merchandising_items: MerchandisingItem[];
  meta_description: string | null;
  meta_title: string | null;
  open_for_seller: boolean;
  order: number;
  parent: LegacyCategoryElement | null;
  path: string;
  picture_id: string;
  redirection_category_id: string | null;
  slug: string | null;
  sort_path: number;
  title: string | null;
  updated_at: string;
}

export interface LegacyCategories {
  headers: any[];
  listing: {
    elements: LegacyCategoryElement[];
  };
  parameters: {
    i18n: LegacyCategoryElementI18n;
  };
}

export interface LegacyCategory {
  description: string | null;
  editorialName: string | null;
  i18nSlugs: Record<Language, string> | null;
  id: string | null;
  level: CategoryLevelDepth | null; // Warning: numeric enums are not type safe
  locale: string | null;
  merchandisingItems: MerchandisingItem[];
  metaDescription: string | null;
  metaTitle: string | null;
  name: string | null;
  parent: LegacyCategory | null;
  redirectionCategoryId: string | null;
  slug: string | null;
}

export interface Category {
  createdAt: string;
  id: string;
  legacyId: string;
  level: number;
  name: string;
  slug: string;
  slugsTree: string[];
  updatedAt: string;
}

interface ApiLegacyProductFlags {
  cdn_remove_background: boolean;
  discount_trade_program: number;
  is_favorite: boolean;
  is_first: boolean;
  is_new_favorite: boolean;
  is_pro_price: boolean;
  is_trade_program: boolean;
  quality_mark: QualityLevel;
}

export interface ApiLegacyProduct {
  flags: ApiLegacyProductFlags;
  image: string;
  isProPrice: string;
  price: string;
  proPrice: string;
  sku: string;
  slug: string;
  specialPrice: string;
  title: string;
}

export interface Media {
 backgroundRemovedMediumId: string;
 id: string;
 originalMediumId: string;
}

interface Material {
  createdAt: string;
  id: string;
  legacyId: string;
  media: Media[];
  name: string;
  slug: string;
  updatedAt: string;
}

export type MainPicture = Image;

export interface ProductPricingPro {
  discount: number;
  isActive: boolean;
  price: number;
}

export interface ProductPricing {
  currency: string;
  id: string;
  isNegotiable?: boolean;
  price: number;
  pro: ProductPricingPro;
  reservedPrice: number;
  specialPrice: number;
}

interface ProductAuthenticity {
  isApproved: boolean;
  isAskedFor: boolean;
}

interface Style {
  createdAt: string;
  id: string;
  legacyId: string;
  media: Media[];
  name: string;
  slug: string;
  updatedAt: string;
}

export const orderedDimensionTypes = ['height', 'width', 'depth'] as const;

type DimensionType = typeof orderedDimensionTypes[number];

type MeasurementsType = DimensionType | 'weight';

type Measurements = Partial<Record<MeasurementsType, { value: number }>>;

export interface Story {
  fact?: string;
  history?: string;
  id: string;
  tips?: string;
  title?: string;
  weLike?: string;
}

export interface Picking {
  address: Address;
  id: string | null;
}

export interface Product {
  __position: number; // AlgoliaProduct['__position']
  __queryID: string; // AlgoliaProduct['__queryID']
  authenticity: ProductAuthenticity;
  batch: number;
  categories?: Category[];
  category?: Category;
  categoryId: Category['id'];
  color: Color | null;
  createdAt: string;
  description: string;
  designer?: Designer | null;
  dimensions: Measurements;
  id: string;
  isHandmade?: boolean | null;
  legacyId: string;
  legacyThreadId: string | null;
  localizedDescription?: Record<string, string>;
  localizedSlug?: Record<string, string>;
  localizedTitle?: Record<string, string>;
  mainPicture: MainPicture;
  material?: Material | null;
  media: Media[];
  objectID: string; // AlgoliaProduct['objectID']
  picking?: Picking;
  pickingId: string;
  pricing?: ProductPricing;
  pricingId: ProductPricing['id'];
  qualityLevel: QualityLevel;
  quantity?: number;
  seoRedirect: SeoRedirect | null;
  shop?: ShopDetails;
  shopId: ShopId;
  sku: string;
  slug: string;
  status: ProductStatus;
  story?: Story;
  style?: Style | null;
  title: string;
  updatedAt: string;
}

export type ProductCore = Pick<
  Product,
  | '__position'
  | '__queryID'
  | 'id'
  | 'isHandmade'
  | 'mainPicture'
  | 'objectID'
  | 'pricing'
  | 'qualityLevel'
  | 'quantity'
  | 'shop'
  | 'sku'
  | 'slug'
  | 'status'
  | 'title'
>;

export type ProductIdentifier = Product['id'] | Product['legacyId'] | Product['sku'];

export interface ProductSelectionTriage {
  createdAt: string;
  id: string;
  product: Product;
}

export interface PickupAddressPublic {
  city: string;
  country: ValueOf<typeof ECountryCodeISO31661>;
  postcode: string;
}

export interface UsableDeliveryOption {
  booster: Price | null;
  delay: string | null;
  id: string;
  image: Image;
  label: string;
  legacyDeliveryOfferId: string;
  pickupAddress: PickupAddressPublic;
  priceBeforeBoost: Price | null;
  provider: ProviderType;
  totalPrice: Price;
}
