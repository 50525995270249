import Vue from 'vue';

import {
  EFacetNameNumericMenu,
  EFacetNameRange,
  EFacetNameRefinementList,
  EFacetNameToggleRefinement,
  FacetNameRange,
  FacetNameToggleRefinement,
} from '@/domain/core/algolia/types';
import { CurrentRefinementList, maxBatchQuantityIndexed } from '@/infrastructure/externalServices/algolia/types';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

type Attribute = CurrentRefinementList['attribute'];

export default Vue.extend({
  data() {
    return {
      refinementAttributesToCapitalize: [
        EFacetNameRefinementList.StyleTitle,
        EFacetNameRefinementList.MaterialTitle,
      ],
    };
  },
  methods: {
    $_currentRefinements_getRefinementDisplayValue(attribute: string, rawLabel: string, value: string | number | null = null): string {
      const facetsI18nPath = 'page.search.facets';
      let label = rawLabel;

      if (this.$_currentRefinements_isColorAttribute(attribute)) {
        label = label.split('||')[0];
      } else if (this.$_currentRefinements_isPriceAttribute(attribute)) {
        label += ' €';
      } else if (this.$_currentRefinements_isDimensionAttribute(attribute)) {
        label += ' cm';
      } else if (this.$_currentRefinements_isAdditionalFiltersAttribute(attribute)) {
        label = this.$t(`${facetsI18nPath}.${attribute}.title`) as string;

        // NOTE: enabled during sales only.
        if (this.$accessor?.featureFlags?.list?.[EFeatureFlags.Sales] && attribute === EFacetNameToggleRefinement.IsDiscounted) {
          label = this.$t('page.search.facets.sales.sale.refinementTitle') as string;
        }
      } else if (this.$_currentRefinements_isStatusAttribute(attribute)) {
        label = this.$t(`${facetsI18nPath}.isSoldOut.title`) as string;
      } else if (this.$_currentRefinements_isCountryAttribute(attribute)) {
        const i18nCountryKey = `page.search.facets.country.values.${label}`;
        const isTranslationAvailable = this.$te(i18nCountryKey);

        label = isTranslationAvailable ? this.$t(i18nCountryKey) as string : label;
      } else if (this.$_currentRefinements_isDepartmentAttribute(attribute)) {
        label = label.split('||')?.[1] || label;
      } else if (this.$_currentRefinements_isQuantityAttribute(attribute) && Number.parseInt(label, 10)) {
        const quantityValue = Number.parseInt(label, 10);
        const isMaxQuantity = quantityValue === maxBatchQuantityIndexed;
        const i18nKey = isMaxQuantity ? 'customLastItemLabel' : 'customLabel';

        label = this.$tc(`page.search.facets.batchQuantity.${i18nKey}`, Number.parseInt(label, 10));
      } else if (this.$_currentRefinements_isPublishedAtAttribute(attribute) && value) {
        label = this.$_currentRefinements_getPublishedAtLabel(Number.parseInt(value as string, 10));
      } else if (this.$_currentRefinements_isDiscountAttribute(attribute)) {
        label = this.$t(`${facetsI18nPath}.sales.superSale.refinementTitle`) as string;
      } else if (this.$_currentRefinements_isHandmadeAttribute(attribute)) {
        const i18nHandmadeKey = `page.search.facets.isHandmade.values.${label}`;
        const isTranslationAvailable = this.$te(i18nHandmadeKey);

        label = isTranslationAvailable ? this.$t(i18nHandmadeKey) as string : label;
      }

      return label;
    },
    $_currentRefinements_isAdditionalFiltersAttribute(attribute: Attribute): boolean {
      const attributes: Partial<FacetNameToggleRefinement>[] = [
        EFacetNameToggleRefinement.IsDeliveryBySelency,
        EFacetNameToggleRefinement.IsDeliveryBySeller,
        EFacetNameToggleRefinement.IsDeliveryCocolis,
        EFacetNameToggleRefinement.IsDeliveryColissimo,
        EFacetNameToggleRefinement.IsDeliveryCustom,
        EFacetNameToggleRefinement.IsDiscounted,
        EFacetNameToggleRefinement.IsNegotiable,
        EFacetNameToggleRefinement.IsProPrice,
        EFacetNameToggleRefinement.IsSellerPro,
        EFacetNameToggleRefinement.IsTradeProgram,
      ];

      return attributes.includes(attribute as FacetNameToggleRefinement);
    },
    $_currentRefinements_isColorAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRefinementList.ColorFacet;
    },
    $_currentRefinements_isCountryAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRefinementList.Country;
    },
    $_currentRefinements_isDepartmentAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRefinementList.Department;
    },
    $_currentRefinements_isDimensionAttribute(attribute: Attribute): boolean {
      const attributes = [
        EFacetNameRange.Depth,
        EFacetNameRange.Height,
        EFacetNameRange.Width,
      ] as FacetNameRange[];

      return attributes.includes(attribute as FacetNameRange);
    },
    $_currentRefinements_isDiscountAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameNumericMenu.Discount;
    },
    $_currentRefinements_isQuantityAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRefinementList.Quantity;
    },
    $_currentRefinements_isPriceAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRange.CurrentPrice;
    },
    $_currentRefinements_isStatusAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameToggleRefinement.Status;
    },
    $_currentRefinements_isHandmadeAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameRefinementList.IsHandmade;
    },
    $_currentRefinements_isPublishedAtAttribute(attribute: Attribute): boolean {
      return attribute === EFacetNameNumericMenu.PublishedAt;
    },
    $_currentRefinements_getPublishedAtLabel(value: number): string {
      const todayTimestampInSec = Date.now() / 1000;
      const timestampsDiff = todayTimestampInSec - value;

      const monthsDiff = Math.floor(timestampsDiff / (3600 * 24 * 30));
      const monthDiffsToLabel: Record<number, string> = {
        3: this.$t('page.search.facets.publicationDate.threeMonths') as string,
        6: this.$t('page.search.facets.publicationDate.sixMonths') as string,
        12: this.$t('page.search.facets.publicationDate.oneYear') as string,
      };

      return monthDiffsToLabel[monthsDiff] || `<= ${value}`;
    },
  },
});
