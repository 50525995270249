import { ValueOf } from 'type-fest';

import { Product } from '@/domain/product/types';
import { UserPublic } from '@/domain/user/types';

export const EOrderEntryReviewStatus = {
  Automatic: 'automatic',
  Published: 'published',
  ToCollect: 'to_collect',
} as const;

export interface OrderEntryReview {
  collectedAt: string;
  comment: string | null;
  id: string | null;
  product: Pick<Product, 'sku' | 'title' | 'mainPicture'>;
  rating: number; // NOTE: >= 1 && <= 5
  status: ValueOf<typeof EOrderEntryReviewStatus>;
}

export type Review = OrderEntryReview & { buyer: UserPublic };
export type ReviewToCollect = OrderEntryReview & { seller: UserPublic };
