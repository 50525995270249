import { render, staticRenderFns } from "./InstantSearchSSR.vue?vue&type=template&id=dd67ce18"
import script from "./InstantSearchSSR.vue?vue&type=script&lang=ts"
export * from "./InstantSearchSSR.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InstantSearchConfigure: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchConfigure.vue').default,InstantSearchHeader: require('/home/runner/work/webapp/webapp/components/organisms/instantsearch/InstantSearchHeader.vue').default,InstantSearchVirtualSearchBox: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchVirtualSearchBox.vue').default,InstantSearchFilters: require('/home/runner/work/webapp/webapp/components/organisms/instantsearch/InstantSearchFilters.vue').default,InstantSearchResults: require('/home/runner/work/webapp/webapp/components/organisms/instantsearch/InstantSearchResults.vue').default,InstantSearchPagination: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchPagination.vue').default})
