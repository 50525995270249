import { ValueOf } from 'type-fest';

import { Product } from '@/domain/product/types';

export const EBackgroundType = {
  Image: 'image',
  SolidColor: 'color',
} as const;

export const EBlockType = {
  Banner: 'banner',
  CallToAction: 'cta',
  Callouts: 'editorial',
  LastViewedItems: 'lastViewedItems',
  MainBlock: 'mainBlock',
  Selection: 'selection',
  Slider: 'slider',
  TailoredItems: 'tailoredItems',
  Typer: 'typer',
} as const;

export const EHomepageStatus = {
  Draft: 'DRAFT',
  Published: 'PUBLISHED',
} as const;

export const EOrientation = {
  Left: 'left',
  Right: 'right',
} as const;

export interface EditoContentHeader {
  header: any[];
}

export interface EditoContentParameters {
  locale: string;
}

export interface EditoContentUser {
  id: string;
}

interface TopBlock {
  active: boolean;
  color: string;
  content: string;
  endingAt: Date;
  fontColor: string;
  id: string;
  locale: string;
  startingAt: Date;
}

export interface PromoBlock {
  categoryId: string;
  id: string;
  link: string;
  locale: string;
  picture: string;
  position: string;
  title: string;
}

interface PromoMenuItem {
  content: string;
  endingAt: Date;
  highlighted: boolean;
  id: string;
  link: string;
  locale: string;
  startingAt: Date;
}

interface ColorTheme {
  active: boolean;
  color: string;
  endingAt: Date;
  fontColor: string;
  id: string;
  locale: string;
  startingAt: Date;
}

export interface EditoHeader {
  headers: EditoContentHeader;
  listing: {
    elements: {
      colorTheme: ColorTheme;
      promoBlock: PromoBlock[];
      promoMenuItem: PromoMenuItem;
      topBlock: TopBlock;
    };
  };
  parameters: EditoContentParameters;
  user: EditoContentUser;
}

export interface BodyMainInspiration {
  backgroundColor?: string;
  backgroundType: string;
  cta: string;
  image?: string;
  link: string;
  title: string;
}

export interface BodySlide {
  cta: string;
  image: string;
  link: string;
}

interface EditoBlockBanner {
  html: string;
}

interface EditoBlockMain {
  first: Partial<Product>;
  fourth: Partial<Product>;
  mainInspiration: BodyMainInspiration;
  orientation: ValueOf<typeof EOrientation>;
  second: Partial<Product>;
  third: Partial<Product>;
}

interface EditoBlockCallToAction {
  backgroundColor: string;
  buttonTitle: string;
  fontColor: string;
  link: string;
  title: string;
}

interface EditoBlockTyper {
  title: string;
  words: string[];
}
export interface EditoInspirations {
  first: BodyMainInspiration;
  second: BodyMainInspiration;
}

interface EditoBlockCallouts {
  inspirations: EditoInspirations;
}

interface EditoBlockCarousel {
  slides: BodySlide[];
}

type EditoBlockSelectionCta = Omit<EditoBlockCallToAction, 'buttonTitle'>;

interface EditoBlockSelection {
  cta: EditoBlockSelectionCta;
  fifth: Partial<Product>;
  first: Partial<Product>;
  fourth: Partial<Product>;
  second: Partial<Product>;
  seventh: Partial<Product>;
  sixth: Partial<Product>;
  third: Partial<Product>;
}

export interface ElementBodyBase {
  css?: string;
  endingAt?: Date | string;
  startingAt?: Date | string;
  type: ValueOf<typeof EBlockType>;
}

export type ElementBody = ElementBodyBase
  & Partial<EditoBlockBanner>
  & Partial<EditoBlockCallouts>
  & Partial<EditoBlockCallToAction>
  & Partial<EditoBlockCarousel>
  & Partial<EditoBlockMain>
  & Partial<EditoBlockSelection>
  & Partial<EditoBlockTyper>;

// TODO: remove below types prefix Api-
export type ApiEditoBlockBanner = ElementBodyBase & EditoBlockBanner;
export type ApiEditoBlockCallouts = ElementBodyBase & EditoBlockCallouts;
export type ApiEditoBlockCallToAction = ElementBodyBase & EditoBlockCallToAction;
export type ApiEditoBlockCarousel = ElementBodyBase & EditoBlockCarousel;
export type ApiEditoBlockMain = ElementBodyBase & EditoBlockMain;
export type ApiEditoBlockSelection = ElementBodyBase & EditoBlockSelection;
export type ApiEditoBlockTyper = ElementBodyBase & EditoBlockTyper;

interface HomepageElement {
  body: ElementBody[];
  created_at: Date;
  id: string;
  language: string;
  name: string;
  status: string;
  updated_at: Date;
}

export interface EditoHomepage {
  headers: EditoContentHeader;
  listing: {
    elements: HomepageElement[];
  };
  parameters: EditoContentParameters;
  user: EditoContentUser;
}

interface SelectionElementProduct {
  sku: string;
}

interface SelectionElementI18n {
  admin_title: string;
  description: string;
  id: string;
  language: string;
  meta_description: string;
  meta_title: string;
  title: string;
}

interface SelectionElement {
  admin_title: string;
  allow_sold_out: boolean;
  banner: string;
  created_at: Date;
  description: string;
  i18n: SelectionElementI18n[];
  id: string;
  inserts?: any;
  main_language: string;
  meta_description: string;
  meta_title: string;
  products: SelectionElementProduct[];
  slug: string;
  title: string;
  updated_at?: Date;
}

export interface EditoSelection {
  headers: EditoContentHeader;
  listing: {
    elements: SelectionElement[];
  };
  parameters: EditoContentParameters;
  user: EditoContentUser;
}

interface EditoRepeatableBlock {
  indexByBlockType?: number;
}

interface LastViewedItems {
  products: Product[];
}

export type EditoBlock = ElementBody
  & EditoRepeatableBlock
  & Partial<LastViewedItems>;
