
import Vue from 'vue';
// @ts-ignore
import { AisCurrentRefinements, AisRefinementList } from 'vue-instantsearch';

import { EFacetNameRefinementList as EFacetList } from '@/domain/core/algolia/types';
import { CurrentRefinementList, RefinementListItem } from '@/infrastructure/externalServices/algolia/types';

const EAvailableCountries = {
  Belgium: 'BE',
  France: 'FR',
  GreatBritain: 'GB',
  Netherlands: 'NL',
} as const;

export default Vue.extend({
  name: 'InstantSearchCheckboxesCountry',
  components: {
    AisCurrentRefinements,
    AisRefinementList,
  },
  props: {
    isCrawlable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EFacetList: Object.freeze(EFacetList),
      activeRefinements: [] as CurrentRefinementList[],
    };
  },
  computed: {
    shouldDisplayDepartments(): boolean {
      const [countryRefinementsList] = this.getCountryRefinements();
      const refinedCountries = countryRefinementsList?.refinements?.map((facet) => facet.value) || [];
      const isFranceRefined = refinedCountries.includes(EAvailableCountries.France);

      return isFranceRefined;
    },
  },
  methods: {
    getCurrentRefinements(items: CurrentRefinementList[]): CurrentRefinementList[] {
      // store refinements in local data, return empty so that widget does not render
      this.activeRefinements = items;

      return [];
    },
    transformCountries(items: RefinementListItem[]): RefinementListItem[] {
      return items
        .filter((item: any) => Object.values(EAvailableCountries).includes(item.value))
        .map((item: any) => {
          const label = this.$t(`page.search.facets.country.values.${item.value}`);

          return {
            ...item,
            label: label || item.value,
          };
        });
    },
    transformDepartments(items: RefinementListItem[]): RefinementListItem[] {
      return items
        .map((item: RefinementListItem) => {
          const { value = '' } = item;
          const label = value.replace('||', ' - ');

          return {
            ...item,
            label,
          };
        })
        .sort((a: RefinementListItem, b: RefinementListItem) => a.label > b.label ? 1 : -1);
    },
    getCountryRefinements(): CurrentRefinementList[] {
      return this.activeRefinements
        ?.filter((refinement: CurrentRefinementList) => refinement.attribute === EFacetList.Country) || [];
    },
  },
});
