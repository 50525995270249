export const EGtmEventCatalog = {
  ClickCategoryMerchandisingItem: 'click_on_cat_merch',
  ClickCategorySeoLink: 'catalog_click_seo_page',
} as const;

export const EGtmEventEcommerce = {
  AddPaymentInfo: 'add_payment_info',
  AddShippingInfo: 'add_shipping_info',
  AddToCart: 'add_to_cart',
  AddToWishlist: 'add_to_wishlist',
  BeginCheckout: 'begin_checkout',
  Purchase: 'purchase',
  RemoveFromCart: 'remove_from_cart',
  SelectItem: 'select_item',
  ViewCart: 'view_cart',
  ViewItem: 'view_item',
  ViewItemList: 'view_item_list',
} as const;

export const EGtmEventCheckout = {
  AddVoucher: 'checkout_add_promo_code',
  ClickPayment: 'checkout_click_payment',
  DisplayDeliveryOptions: 'display_delivery_options',
  DisplayServiceFees: 'display_service_fees_popin',
  SelectHandling: 'checkout_click_by_selency_handling',
  SelectPlace: 'select_place',
  ViewDeliveryAddress: 'checkout_view_delivery_address',
} as const;

export const EGtmEcommerceListName = {
  BestDeals: 'Best deals',
  Category: 'Category',
  CheckoutCart: 'Checkout cart',
  CheckoutPayment: 'Checkout payment',
  Designers: 'Designers',
  LastItemsViewed: 'Last seen products',
  Search: 'Search',
  SearchPreview: 'Search preview',
  Shop: 'Shop',
  SimilarProducts: 'Similar products',
} as const;

export const EGtmEventHeader = {
  ClickBanner: 'header_click_top_block',
  ClickLinkMainNav: 'header_click_link',
  ClickProLink: 'header_click_selency_pro',
  ClickPromoMainNav: 'header_click_promo_block',
} as const;

export const EGtmEventHomepage = {
  ClickBanner: 'homepage_click_banner',
  ClickCarousel: 'homepage_click_slider_image',
  ClickEditoMain: 'homepage_click_editorial_block',
  ClickLastViewedItems: 'click_on_previous_item',
} as const;

export const EGtmEventSearch = {
  ClickPopular: 'search_click_popular_search',
  ClickRecent: 'search_click_recent_search',
  ClickSaved: 'search_click_save_search',
  ClickSuggestion: 'search_click_search_suggestion',
  GetResults: 'search_results',
  Submit: 'search',
  ToggleAlert: 'search_click_activate_search',
} as const;

export const EGtmToggleSearchAlertLocation = {
  SearchBar: 'search_bar',
} as const;

export const EGtmToggleSearchAlertStatus = {
  Disabled: 'disabled',
  Enabled: 'enabled',
} as const;

export const EGtmEventUser = {
  Login: 'login',
  NewsletterOptin: 'newsletter_submit_optin',
  Register: 'sign_up',
} as const;

export const ENewsletterSubmitLocation = {
  Footer: 'footer',
  Popin: 'popin',
  SignUp: 'sign_up',
  SignUpOneClick: 'sign_up_one_click',
} as const;

export const EGtmEventInit = {
  DLInit: 'datalayer_init',
} as const;

export const EGtmPageType = {
  Homepage: 'home',
} as const;

export const EGtmEventProduct = {
  AskPictures: 'product_click_ask_pictures',
  ClickNegotiate: 'click_negotiate',
  ClickSeeSimilarProducts: 'click_see_similar_items_page',
  ContactSeller: 'product_click_contact_seller',
  DeliveriesExpand: 'product_click_shipping_options',
  Description: 'product_click_description',
  LinkToSellerShop: 'product_click_all_items_from_seller',
  MakeAnOffer: 'make_an_offer',
  ScrollToSimilarItems: 'product_click_see_similar_items_section',
  ShippingOptions: 'view_page.item_details',
} as const;

export const EGtmAuthenticationMethod = {
  Apple: 'Apple',
  Email: 'Email',
  Google: 'Google',
} as const;

export const EGtmEventReview = {
  ClickRateButton: 'rate_click_rate',
  ClickRatingStar: 'rate_click_star',
  ClickSuggestion: 'rate_click_comment',
  Submit: 'rate_submit_form',
  ViewModal: 'rate_view_modal',
} as const;

export const EGtmViewModal = {
  AddressSelection: 'view_modal_address_selection',
  AppDownloadPromptMobile: 'view_modal_app_download_prompt_mobile',
  BuyerProtection: 'view_modal_buyer_protection', // Duplicate of EGtmEventCheckout.DisplayServiceFees (legacy checkout event)
  PickupPoints: 'view_modal_pickup_points',
  ProductImageZoom: 'view_modal_product_zoom',
  ProductNegotiate: 'view_modal_product_negotiate',
  ProductShopReviews: 'view_modal_product_shop_reviews',
  QRCodeDesktopAppDownload: 'view_modal_qr_code_desktop_app_download',
  Search: 'view_modal_search',
  SplitPayment: 'view_modal_split_payment',
  WishlistBoardCreation: 'view_modal_wishlist_board_creation',
} as const;

export const EPlaceSelectionLocation = {
  FormAddress: 'form_address',
  PickupPointSelection: 'pickup_point_selection',
  ProductDeliveryOptions: 'product_delivery_options',
} as const;

export const EGtmPickupPoint = {
  ClickDisplayList: 'pickup_point_click_display_list',
  ClickDisplayMap: 'pickup_point_click_display_map',
  ClickOpeningTimes: 'pickup_point_click_opening_times',
  ClickSelectFromList: 'pickup_point_click_select_from_list',
  ClickSelectFromMap: 'pickup_point_click_select_from_map',
  ClickUpdate: 'pickup_point_click_update',
} as const;
