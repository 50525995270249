import { render, staticRenderFns } from "./InstantSearchHeader.vue?vue&type=template&id=141e8fa5"
import script from "./InstantSearchHeader.vue?vue&type=script&lang=ts"
export * from "./InstantSearchHeader.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSelect: require('/home/runner/work/webapp/webapp/components/atoms/form/input/BaseSelect.vue').default,ButtonToggleFilters: require('/home/runner/work/webapp/webapp/components/molecules/button/ButtonToggleFilters.vue').default})
