export const ERouteName = {
  AccountReviews: 'account-reviews',
  Category: 'category',
  Checkout: 'checkout',
  CheckoutCart: 'checkout-cart',
  CheckoutDelivery: 'checkout-delivery',
  CheckoutPayment: 'checkout-payment',
  CheckoutSuccess: 'checkout-success',
  Contact: 'contact',
  Designers: 'designers',
  Handmade: 'handmade',
  Homepage: 'index',
  LatestFinds: 'latestFinds',
  Product: 'p-sku-slug',
  ProductSimilars: 'p-sku-slug-similars',
  Sales: 'sales',
  Search: 'search',
  SearchSeo: 'searchSeo',
  Selections: 'selections-reference-slug',
  ShopsId: 'shops-id',
} as const;

export const ERouteBasePath = {
  Account: '/account',
  Category: '/c',
  Checkout: '/checkout',
  Product: '/p',
  Selections: '/selections',
} as const;
