import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _573ace87 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2d99253a = () => interopDefault(import('../pages/badge.vue' /* webpackChunkName: "pages/badge" */))
const _11ee1284 = () => interopDefault(import('../pages/botmind-contact-form.vue' /* webpackChunkName: "pages/botmind-contact-form" */))
const _a80fc892 = () => interopDefault(import('../pages/category.vue' /* webpackChunkName: "" */))
const _0349d002 = () => interopDefault(import('../pages/cdn-error.vue' /* webpackChunkName: "pages/cdn-error" */))
const _2c817002 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _0a12b1e0 = () => interopDefault(import('../pages/checkout/cart.vue' /* webpackChunkName: "pages/checkout/cart" */))
const _6e5e74b4 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _4b880c76 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _5bbfd3f3 = () => interopDefault(import('../pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _96f5f912 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0684e302 = () => interopDefault(import('../pages/designers.vue' /* webpackChunkName: "pages/designers" */))
const _13b53c86 = () => interopDefault(import('../pages/handmade.vue' /* webpackChunkName: "pages/handmade" */))
const _1d65b3a1 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "" */))
const _96878be0 = () => interopDefault(import('../pages/account/reviews.vue' /* webpackChunkName: "pages/account/reviews" */))
const _cdf92b3e = () => interopDefault(import('../pages/sales/winter.vue' /* webpackChunkName: "pages/sales/winter" */))
const _7f8982e9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _57f48f39 = () => interopDefault(import('../pages/preview/homepage/_id.vue' /* webpackChunkName: "pages/preview/homepage/_id" */))
const _7d25f47f = () => interopDefault(import('../pages/shops/_id.vue' /* webpackChunkName: "pages/shops/_id" */))
const _3d93ae8a = () => interopDefault(import('../pages/wishlists/_wishlistId/boards/_boardId.vue' /* webpackChunkName: "pages/wishlists/_wishlistId/boards/_boardId" */))
const _71cbb094 = () => interopDefault(import('../pages/p/_sku/_slug/index.vue' /* webpackChunkName: "pages/p/_sku/_slug/index" */))
const _61a2fef4 = () => interopDefault(import('../pages/selections/_reference/_slug.vue' /* webpackChunkName: "pages/selections/_reference/_slug" */))
const _77023016 = () => interopDefault(import('../pages/p/_sku/_slug/similars.vue' /* webpackChunkName: "pages/p/_sku/_slug/similars" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _573ace87,
    name: "account"
  }, {
    path: "/badge",
    component: _2d99253a,
    name: "badge"
  }, {
    path: "/botmind-contact-form",
    component: _11ee1284,
    name: "botmind-contact-form"
  }, {
    path: "/c/:categoryLvl1Title/:categoryLvl2Title?/:categoryLvl3Title?",
    component: _a80fc892,
    name: "category"
  }, {
    path: "/cdn-error",
    component: _0349d002,
    name: "cdn-error"
  }, {
    path: "/checkout",
    component: _2c817002,
    name: "checkout",
    children: [{
      path: "cart",
      component: _0a12b1e0,
      name: "checkout-cart"
    }, {
      path: "delivery",
      component: _6e5e74b4,
      name: "checkout-delivery"
    }, {
      path: "payment",
      component: _4b880c76,
      name: "checkout-payment"
    }, {
      path: "success",
      component: _5bbfd3f3,
      name: "checkout-success"
    }]
  }, {
    path: "/contact",
    component: _96f5f912,
    name: "contact"
  }, {
    path: "/designers",
    component: _0684e302,
    name: "designers"
  }, {
    path: "/handmade",
    component: _13b53c86,
    name: "handmade"
  }, {
    path: "/search.html",
    component: _1d65b3a1,
    name: "search"
  }, {
    path: "/account/reviews",
    component: _96878be0,
    name: "account-reviews"
  }, {
    path: "/sales/winter",
    component: _cdf92b3e,
    name: "sales-winter"
  }, {
    path: "/",
    component: _7f8982e9,
    name: "index"
  }, {
    path: "/preview/homepage/:id?",
    component: _57f48f39,
    name: "preview-homepage-id"
  }, {
    path: "/shops/:id?",
    component: _7d25f47f,
    name: "shops-id"
  }, {
    path: "/wishlists/:wishlistId?/boards/:boardId?",
    component: _3d93ae8a,
    name: "wishlists-wishlistId-boards-boardId"
  }, {
    path: "/p/:sku?/:slug",
    component: _71cbb094,
    name: "p-sku-slug"
  }, {
    path: "/selections/:reference?/:slug?",
    component: _61a2fef4,
    name: "selections-reference-slug"
  }, {
    path: "/p/:sku?/:slug?/similars",
    component: _77023016,
    name: "p-sku-slug-similars"
  }, {
    path: "/latest-finds",
    component: _1d65b3a1,
    name: "latestFinds"
  }, {
    path: "/s/:slug",
    component: _1d65b3a1,
    name: "searchSeo"
  }],

  parseQuery: function(queryString) {
    return require('qs').parse(queryString);
  },
  stringifyQuery: function(object) {
    const queryString = require('qs').stringify(object);

    return queryString ? '?' + queryString : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
